


















import {Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    components: { DynamicForm: () => import('@/components/Forms/DynamicForm.vue') }
})

export default class DynamicFormModal extends Vue {
  @Prop(String) ID;
  @Prop(String) action;
  @Prop(Function) callbackAfterSave;
  @Prop([String, Boolean]) isModalForm;
  @Prop([String, Boolean]) isModalDynamicForm;
  @Prop(Object) parentFormData;
  @Prop(String) subFormField;
  @Prop(Object) subFormFieldInfo;
  @Prop(String) dynModuleName;
  @Prop(Object) initParams;

  refreshTheList() {
    this.$emit("refreshTheList");
  }
}
